<div class="border-b border-gray-200 py-12">
  <div class="flex flex-col md:flex-row gap-4 md:gap-10">
    <div>
      <div class="basis-1/3 relative min-w-48 aspect-[1/1]">
        @if (images()[0]; as image) {
          <img class="object-cover rounded-lg h-full"
               fill=""
               [alt]="image.alt"
               [ngSrc]="image.media"
               sizes="20vw">
        } @else {
          <img class="object-cover rounded-lg h-full"
               fill=""
               [alt]="'product/'+ product().id + '/name' | translate"
               ngSrc="assets/images/placeholder.webp"
               sizes="20vw">
        }
      </div>
    </div>
    <div class="basis-2/3">
      <div class="flex flex-row place-content-between gap-2 md:gap-8">
        <div>
          <div class="flex justify-between items-center">
            <a [routerLink]="['/product', product().slug]"
               class="text-100ciels-300 font-semibold text-md md:text-lg">
              {{ 'product/' + product().id + '/name' | translate }}
            </a>
          </div>
          <div class="flex gap-2 mt-2 mb-6 items-center">
            <img alt="" class="w-4 h-4 md:w-6 md:h-6" height="26" ngSrc="assets/icons/gray/location.svg" width="26">
            <p class="text-sm md:text-md font-semibold">{{ 'les100ciels' | translate }} {{ siteService.center()!.name }}</p>
          </div>
        </div>
        @if (!cartService.saving() && canDelete()) {
          <div class="flex flex-col gap-4 items-center">
            @if (!asCoupon()) {
              <button
                (click)="addItemToCart()"
                (keyup.enter)="emulateClick($event)"
                role="button" tabindex="0">
                <img alt="" height="32" width="32" ngSrc="assets/icons/gray/one_more.svg">
              </button>
            }
            <button
              (click)="removeItemFromCart()"
              (keyup.enter)="emulateClick($event)"
              role="button" tabindex="0">
              <img alt="" height="36" width="36" ngSrc="assets/icons/gray/trashcan.svg">
            </button>
          </div>
        }
      </div>

      @if (item().type !== CartItemType.Coupon && siteService.price(product().id); as p) {
        <p>
            <span class="font-semibold text-xl">
              @if (p.fullPrice) {
                <span class="text-100ciels-300 mr-4 line-through">{{ p.fullPrice | currency }}</span>
              }
              <span
                [class.text-100cielsGift-500]="p.fullPrice"
                [class.text-100ciels-300]="!p.fullPrice">
                {{ p.price | currency }}
              </span>
            </span> / {{ 'unitPrice' | translate }}
        </p>
        @if (showPlanning) {
          @if (spaBookingData(); as spaBookingData) {
            <div class="py-6">
              @if (spaBookingData.item.spaBooking) {
                <p>
                  {{ 'yourReservation' | translate }} : {{ spaBookingData.item.spaBooking.date | date: 'fullDate' }}
                  {{ 'at' | translate }}
                  {{ spaBookingData.item.spaBooking.time }}
                  @if (spaBookingTimer()) {
                    <br/>
                    {{ 'remainingTimeIs' | translate }} {{ spaBookingTimer() }} {{ 'minutes' | translate }}
                  }
                </p>
                @if (!planningDisplayed()) {
                  <button (click)="planningDisplayed.set(true)"
                          (keyup.enter)="emulateClick($event)"
                          [disabled]="cartService.saving()"
                          class="px-6 gap-2 w-full mt-4 py-3 main-btn text-center flex items-center justify-center disabled:bg-gray-300"
                          appHoverGradient>
                    {{ 'modify' | translate }}
                  </button>
                }
              } @else {
                @if (!planningDisplayed()) {
                  <button (click)="planningDisplayed.set(true)"
                          (keyup.enter)="emulateClick($event)"
                          [disabled]="cartService.saving()"
                          class="px-6 gap-2 w-full mt-4 py-3 main-btn text-center flex items-center justify-center disabled:bg-gray-300"
                          appHoverGradient>
                    {{ 'reserveASlot' | translate }} ?
                  </button>
                }
              }
            </div>
          } @else {
            @if (spaBookingProgress()) {
              <div class='w-full pt-8'>
                <div class='h-1 w-full bg-gray-300 overflow-hidden'>
                  <div class='progress w-full h-full bg-100ciels-300 left-right'></div>
                </div>
              </div>
            }
          }
        }
      } @else {
        <p class="mb-2">
        <span class="font-semibold text-xl">
          <span class="text-100ciels-300 mr-4">{{ 'free' | translate }}</span>
        </span>
        </p>

        @if (asCoupon(); as couponCartItem) {
          <div class="text-sm m-4">
            Valable jusqu'au <strong>{{ couponCartItem.validUntil | date: 'shortDate' }}</strong> dans le(s) centre(s)
            de
            <strong>{{ couponCartItem.validInStr }}</strong>
          </div>
          @for (consumable of couponCartItem.consumables; track consumable) {
            <div class="mb-2">
              @switch (selectedType(consumable)) {
                @case ('anonymous') {
                  <div class="m-2">
                    {{ 'voucherGuideAnonymous' | translate }}
                  </div>
                }
                @case ('spa') {
                  <div class="m-2">
                    @if (checkout()) {
                      {{ 'voucherGuideSpaCheckout' | translate }}
                    } @else {
                      {{ 'voucherGuideSpa' | translate }}
                    }
                  </div>
                }
                @case ('credit') {
                  <div class="m-2">
                    @if (checkout()) {
                      {{ 'voucherGuideCreditCheckout' | translate }}
                    } @else {
                      {{ 'voucherGuideCredit' | translate }}
                    }
                  </div>
                }
              }
            </div>
          }
        }
      }
      @if (asGift(); as giftItem) {
        @if (giftItem.format === GiftFormat.mail) {
          <div class="flex items-center justify-between text-md mt-2">
            <p class="text-sm md:text-md">{{ 'managementFees' | translate }}</p>
            <p class="font-semibold text-sm md:text-md">
              {{ siteService.price(productService.managementFee().id).price | currency }}
            </p>
          </div>
        }
        <p class="text-xs md:text-sm">{{ 'validUntil' | translate : { date: nextYear | date } }}</p>
      }
    </div>
  </div>

  @if (showPlanning) {
    @if (planningDisplayed()) {
      @if (spaBookingData(); as spaBookingData) {
        @for (spaBooking of spaBookingData.spaBookings; track spaBooking.uid) {
          <app-spa-booking [center]="spaBookingData.center" [spaBooking]="spaBooking"
                           (validated)="updateSpaOption($event)"></app-spa-booking>
        }
        <button class="px-6 gap-2 w-full mt-4 py-3 main-btn text-center flex items-center justify-center
          bg-red-500 hover:bg-red-400 disabled:bg-gray-300"
                (click)="planningDisplayed.set(false)"
                (keyup.enter)="emulateClick($event)"
                [disabled]="cartService.saving()">
          {{ 'cancel' | translate }}
        </button>
      }
    }
  }

  @if (item().type !== CartItemType.Coupon) {
    <div class="flex mt-7 justify-between items-center">
      <div class="flex gap-4 items-center">
        <img width="40" height="40" class="h-9 w-9 md:h-14 md:w-14" alt="" ngSrc="assets/icons/gray/gift.svg">
        <p class="font-semibold text-base lg:text-lg">{{ 'giftOption' | translate }}</p>
      </div>
      @if (asGift() && !editGift()) {
        <div class="text-sm p-8 leading-6">
          <button (click)="editGift.set(!editGift())"
                  (keyup.enter)="emulateClick($event)"
                  role="button" tabindex="0">
            <img alt="" height="32" width="32" ngSrc="assets/icons/gray/edit.svg">
          </button>
        </div>
      }
      <button type="button" [ngClass]="asGift() ? 'bg-100ciels-300' : 'bg-gray-500'"
              (click)="cartService.switchGift(item())"
              [disabled]="cartService.saving()"
              class="relative inline-flex h-6 md:h-8 w-12 md:w-16 flex-shrink-0 cursor-pointer rounded-full border-4 border-transparent transition-colors duration-200 ease-in-out focus:outline-none"
              role="switch" aria-checked="false" aria-labelledby="annual-billing-label">
              <span aria-hidden="true"
                    [ngClass]="asGift() ? 'md:translate-x-8 translate-x-6' : 'translate-x-0'"
                    class="translate-x-0 pointer-events-none inline-block h-4 w-4 md:h-6 md:w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"></span>
      </button>
    </div>
  }

  @if (asGift(); as giftItem) {
    <app-gift-form [gift]="giftItem" [handleEdit]="editGift()"
                   (giftFormSubmitted)="updateGiftData($event)" theme="checkout"></app-gift-form>
  }
</div>
